.ml-auto { margin-left: auto; }

.v-tbtm { vertical-align: text-bottom; }
.v-ttop { vertical-align: text-top; }

.minw-1 { min-width: 16px; }
.minw-2 { min-width: 24px; }
.minw-3 { min-width: 32px; }
.minw-4 { min-width: 60px; }
.minw-5 { min-width: 80px; }
.minw-6 { min-width: 200px; }
.minw-7 { min-width: 240px; }

.push-1 { margin-left: 16px; }
.push-2 { margin-left: 24px; }
.push-3 { margin-left: 32px; }
.push-4 { margin-left: 60px; }
.push-5 { margin-left: 80px; }
.push-6 { margin-left: 200px; }
.push-7 { margin-left: 240px; }

.w7 { width: 240px; }

.w-25-rem { width: 25rem }

.accordionIndicator {
  transition: all 0.75s cubic-bezier(0,1,0,1);
  &.isExpanded {
    transform: rotate(90deg);
  }
}

/* Hacks
 * =====
 * These are hacks that help us deal with purple/purple3 clashes.
 * They should not find their way upstream.
 */

.seriously-no-outline {
    outline: 0 !important;
    outline-color: transparent !important;
    outline-style: none !important;
    text-decoration-color: none;
    text-decoration-style: none;
    text-decoration: none;
}
