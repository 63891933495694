@import "~client/styles/purple3Colors.scss";

.tooltip {
  background-color: $dark-gray !important;
  padding: 8px 12px !important;
  font-size: 12px !important;

  &.place-top {
    &:after {
      border-top-color: $dark-gray !important;
      border-top-style: solid !important;
      border-top-width: 6px !important;
    }
  }

  &.place-right {
    &:after {
      border-right-color: $dark-gray !important;
      border-right-style: solid !important;
      border-right-width: 6px !important;
    }
  }

  &.place-bottom {
    &:after {
      border-bottom-color: $dark-gray !important;
      border-bottom-style: solid !important;
      border-bottom-width: 6px !important;
    }
  }

  &.place-left {
    &:after {
      border-left-color: $dark-gray !important;
      border-left-style: solid !important;
      border-left-width: 6px !important;
    }
  }
}
