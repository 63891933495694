@import "./purple3Colors";

.belostick-footer ul {
  list-style: none;
  padding: 0 10px;
}

a {
  color: $purple;
}

.rotate-90 { -webkit-transform: rotate( 90deg ); transform: rotate( 90deg ); }
