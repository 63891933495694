$black: #000;
$near-black: #4E535A;
$dark-gray:#596981;
$mid-gray:#96A3B6;
$gray: #96A3B6;
$light-gray: #CFD7E6;
$silver: #E3E7EF;
$light-silver: #EEF1F6;
$lightest-silver: #F7F8FB;
$moon-gray: #ccc;
$near-white: #FBFBFD;
$white: #fff;

$brand: #79589F;
$highlight: #56CDFC;
$success: #74C080;
$warning: #FA9F47;

$well-gray: #EEF1F6;

$transparent:transparent;

$black-90: rgba(0,0,0,.9);
$black-80: rgba(0,0,0,.8);
$black-70: rgba(0,0,0,.7);
$black-60: rgba(0,0,0,.6);
$black-50: rgba(0,0,0,.5);
$black-40: rgba(0,0,0,.4);
$black-30: rgba(0,0,0,.3);
$black-20: rgba(0,0,0,.2);
$black-10: rgba(0,0,0,.1);
$black-05: rgba(0,0,0,.05);
$black-025: rgba(0,0,0,.025);
$black-0125: rgba(0,0,0,.0125);

$white-90: rgba(255,255,255,.9);
$white-80: rgba(255,255,255,.8);
$white-70: rgba(255,255,255,.7);
$white-60: rgba(255,255,255,.6);
$white-50: rgba(255,255,255,.5);
$white-40: rgba(255,255,255,.4);
$white-30: rgba(255,255,255,.3);
$white-20: rgba(255,255,255,.2);
$white-10: rgba(255,255,255,.1);
$white-05: rgba(255,255,255,.05);
$white-025: rgba(255,255,255,.025);
$white-0125: rgba(255,255,255,.0125);

$dark-red:  #e7040f;
$red:  #D64242;
$light-red:  #E78E8E;
$orange:  #FA9F47;
$light-orange:  #FCC691;
$gold:  #ffb700;
$yellow:  #ffd700;
$light-yellow:  #fbf1a9;
$purple:  #79589F;
$light-purple:  #A996C0;
$dark-pink:  #d5008f;
$hot-pink: #ff41b4;
$pink:  #ff80cc;
$light-pink:  #ffa3d7;
$dark-green:  #137752;
$green:  #74C080;
$light-green: #AAD8B3;
$navy:  #001b44;
$dark-blue:  #00449e;
$blue:  #408FEC;
$light-blue:  #8CBCF3;
$lightest-blue:  #F6FAFF;
$washed-blue:  #f6fffe;
$washed-green:  #e8fdf5;
$washed-yellow:  #fffceb;
$washed-red:  #ffdfdf;