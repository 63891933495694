$brand-primary: #79589F;
$hero-color: #534A93;

// Blue
$brand-info: #56CDFC;

// Green
$brand-success: #74C080;

// Yellow
$brand-warning: #FA9F47;

// Red
$brand-danger: #D64242;

// Grays
$gray-lighter: #E7E7EC;
$gray-light: #CBCBD2;
$gray: #7D7D8E;
$gray-dark: #3F3F44;

// as sampled from dashboard
$gray-bg-light: #F5F5F5;
$gray-bg-cardHeader: #F8F8F9;
$gray-bg: #f7f8fb;

// From purple3, for the MOTD banner text
$blue:  #408FEC;
