@import "./colors.scss";
@import "./fonts.scss";
@import "./reactTooltip.scss";
@import "./reactTabs.scss";
@import "./tachyons-flexbox.css";
@import "./purple3_candidates.scss";
@import "./monaco.scss";
@import "~@heroku/react-hk-components/dist/umd/react-hk-components.css";

html {
  height: 100%;
}

html, body {
  font-family: "Salesforce Sans", sans-serif;
}

* {
  -webkit-font-smoothing: subpixel-antialiased !important;

  @media
  (-webkit-min-device-pixel-ratio: 2),
  (min-resolution: 192dpi) {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
  }
}

.app-container {
  display: flex;
  flex: 1 0 auto;
  min-height: 0;
  flex-direction: column;
}

body.dataclip-detail {
  @media screen and (min-width: 60em) {
    .app-container {
      flex: 1;
    }
  }
}

#belostick {
  flex-shrink: 0;
}

body {
  display: flex;
  flex-direction: column;
  height: 100%;

  .glostick {
    flex: 0 0 50px;
  }
}

.header-shadow {
  box-shadow: 4px 4px 8px 0 rgba(0,0,0,.03)
}

.color-inherit {
  fill: currentColor;
}

.bg-white-ns {
  @media screen and (min-width: 30em) {
    background-color: white;
  }
}

.minw-7-l {
  @media screen and (min-width: 60em) {
    min-width: 240px;
  }
}

.expensive-query-code-block {
  min-height: 28em;
  margin: 0;
}

.postgresExpensiveQueriesSection {
  position: relative;
}

.dataclip-name-col {
  max-width: 240px;
}

.dataclip-datastore-col {
  min-width: 240px;
  width: 300px;
}

.dataclip-time-col {
  min-width: 120px;
}

.datastore-name-col {
  width: 280px;
  max-width: 280px;

  .truncate {
    min-height: 16px;
  }
}

.datastore-plan-col {
  width: 200px;
  min-width: 200px;
}

.datastore-app-col {
  width: 240px;
  min-width: 240px;
}

.grid-right {
  @media screen and (min-width: 60em) {
    min-height: 0;
    flex-basis: 300px;
    flex-shrink: 0;
    width: 300px;
  }
}

.scrollable-schema-list {
  flex: 1 1 auto;
  overflow: auto;
  min-height: 0px;
  height: 300px;
  @media screen and (min-width: 60em) {
    height: 100%;
  }
}

.dataclipResult-chart {
  height: 300px;

  @media screen and (min-width: 60em) {
    height: 100%;
  }
}

.dataclip-result-wrap .ReactTable .rt-tr-group {
  flex-grow: 0
}

.dataclip-result-wrap .ReactTable:not(.has-pagination) .rt-tbody .rt-tr-group:last-child {
  border-bottom: 1px solid rgba(0, 0, 0, .05);
}

.dataclip-result-wrap .rt-resizable-header-content div {
  text-transform: none;
}

.motd {
  a, a:visited {
    color: $blue;
  }
}

